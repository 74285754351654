import { Box, Image, Text } from '@mantine/core';
import DoubleBarleyTop from 'src/Icon/DoubleBarleyTop';
import Position from './Position';
import Description from './Description';

export default function Profile({
  img,
  korName,
  engName,
  position,
}: {
  img: string;
  korName: string;
  engName: string;
  position: string;
}) {
  return (
    <Box
      sx={{
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ width: 'fit-content' }}>
        <DoubleBarleyTop
          style={{ top: '49px', position: 'relative', zIndex: 1 }}
        />
        <Image src={img} width={315} height={315} />
      </Box>
      <Box
        sx={{
          width: '315px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text size={20} sx={{ lineHeight: '25px', fontWeight: 200 }} mt={16}>
          {korName}
        </Text>
        <Text size={20} sx={{ lineHeight: '25px', fontWeight: 400 }}>
          {engName}
        </Text>
        <Position>{position}</Position>
      </Box>
      <Text
        align='center'
        color='#D3D3D3'
        size={15}
        mt={24}
        sx={{ lineHeight: '22.5px', zIndex: 2 }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        <br />
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
        ipsum suspendisse ultrices gravida.
        <br />
        Risus commodo viverra maecenas accumsan lacus vel facilisis.
        <br />
        More Details can be added like personal information, education, or
        career background
      </Text>
      <div style={{ marginTop: '20px' }} />
      <Description>Example 1</Description>
      <Description>Example 2</Description>
      <Description>Example 3</Description>
    </Box>
  );
}
