import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { LoadingOverlay } from '@mantine/core';
import RootLayout from './pages/RootLayout';
import About from './pages/About';
import AboutUs from './pages/AboutUs';

const AgeAuthPage = lazy(() => import('./pages/AgeAuth'));
const Home = lazy(() => import('./pages/home'));
const Shop = lazy(() => import('./pages/Shop'));
const ShopDetail = lazy(() => import('./pages/ShopDetail'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path='/'
        element={<RootLayout />}
        errorElement={<div>error is occured</div>}
      >
        <Route
          index
          element={
            <Suspense fallback={<LoadingOverlay visible={true} />}>
              <AgeAuthPage />
            </Suspense>
          }
        />
        <Route
          path='/home'
          element={
            <Suspense fallback={<LoadingOverlay visible={true} />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path='/about'
          element={
            <Suspense fallback={<LoadingOverlay visible={true} />}>
              <About />
            </Suspense>
          }
        />
        <Route
          path='/aboutus'
          element={
            <Suspense fallback={<LoadingOverlay visible={true} />}>
              <AboutUs />
            </Suspense>
          }
        />
        <Route
          path='/shop'
          element={
            <Suspense fallback={<LoadingOverlay visible={true} />}>
              <Shop />
            </Suspense>
          }
        />
        <Route
          path='/shop-detail'
          element={
            <Suspense fallback={<LoadingOverlay visible={true} />}>
              <ShopDetail />
            </Suspense>
          }
        />
      </Route>
    </>,
  ),
);

export default function Router() {
  return <RouterProvider router={router} />;
}
