import { createStyles } from '@mantine/core';
import homeHeaderBackground from 'src/asset/image/home/home-header-background.webp';

const useHomePageStyles = createStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: '100vh',
    backgroundImage: `url(${homeHeaderBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  inner: {
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1,
  },
}));

export default useHomePageStyles;
