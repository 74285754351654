import { Box, Center, Container, Grid, Image, Text } from '@mantine/core';
import BarleyTitle from 'src/components/common/BarleyTitle';
import ArrowDown from 'src/Icon/ArrowDown';
import LogoColored from 'src/Icon/LogoColored';
import useHomePageStyles from '../home/styles/useHomePageStyles';
import About1 from 'src/asset/image/about_1.jpg';
import About2 from 'src/asset/image/about_2.jpg';
import About3 from 'src/asset/image/about_3.jpg';
import About4 from 'src/asset/image/about_4.jpg';

function About() {
  const { classes } = useHomePageStyles();
  const { wrapper } = classes;
  return (
    <>
      <Box component='section' className={wrapper}>
        <Center mt={96} sx={{ flexDirection: 'column' }}>
          <LogoColored size={342} />
          <Text
            align='center'
            color='white'
            size={18}
            mt={42}
            sx={{ lineHeight: '22.5px', zIndex: 2 }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            <br />
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Quis ipsum suspendisse ultrices gravida.
            <br />
            Risus commodo viverra maecenas accumsan lacus vel facilisis.
          </Text>
          <ArrowDown style={{ position: 'absolute', bottom: '4%' }} />
        </Center>
      </Box>
      <section>
        <BarleyTitle>WHISKY NAVI</BarleyTitle>
        <Container px={42} my={84}>
          <Grid gutter={0} columns={1}>
            <Grid.Col>
              <Image src={About1} />
            </Grid.Col>
          </Grid>
          <Grid gutter={0} columns={4}>
            <Grid.Col span={1} sx={{ padding: '31px' }}>
              WHAT WE MAKE
            </Grid.Col>
            <Grid.Col span={1}>
              <Grid gutter={0} columns={1} sx={{ height: '100%' }}>
                <Grid.Col sx={{ height: '50%' }} />
                <Grid.Col sx={{ backgroundColor: '#EFEFEF', height: '50%' }} />
              </Grid>
            </Grid.Col>
            <Grid.Col span={2}>
              <Image src={About2} />
            </Grid.Col>
          </Grid>
          <Grid gutter={0} columns={2}>
            <Grid.Col span={1}>
              <Image src={About3} />
            </Grid.Col>
            <Grid.Col
              span='auto'
              sx={{ paddingTop: '31px', paddingLeft: '35px' }}
            >
              WHAT WE DO
            </Grid.Col>
            <Grid.Col span='auto' sx={{ backgroundColor: '#FFCB14' }} />
          </Grid>
          <Grid gutter={0} columns={2}>
            <Grid.Col
              span='auto'
              sx={{ paddingTop: '36px', paddingLeft: '31px' }}
            >
              HOW WE HANDLE
            </Grid.Col>
            <Grid.Col span={1}>
              <Image src={About4} />
            </Grid.Col>
          </Grid>
          <Grid
            gutter={0}
            columns={2}
            sx={{ height: '200px', maxHeight: 'fit-content' }}
          >
            <Grid.Col span={1} sx={{ backgroundColor: '#F4F4F4' }} />
            <Grid.Col span={1} sx={{ backgroundColor: '#FF9A03' }} />
          </Grid>
        </Container>
      </section>
    </>
  );
}

export default About;
