import { Box, Header } from '@mantine/core';
import { Link } from 'react-router-dom';
import MyPage from 'src/Icon/MyPage';
import Search from 'src/Icon/Search';
import Basket from 'src/Icon/Basket';
import LogoWhite from 'src/Icon/LogoWhite';
import HeaderLink from './HeaderLink';
import useHeaderStyles from './useHeaderStyles';

export default function HomeHeader({ isAuth }: { isAuth: boolean }) {
  const { classes } = useHeaderStyles(isAuth);
  const { wrapper, inner, list } = classes;

  return (
    <Header height={96} className={wrapper}>
      <Box component='nav' className={inner} px={100} my={20}>
        <Box sx={{ display: 'flex' }}>
          <Box component={Link} to='/' sx={{ height: '48px' }}>
            <LogoWhite />
          </Box>
          <Box component='ul' className={list}>
            <HeaderLink />
            <HeaderLink to='about' />
            <HeaderLink to='shop' />
            <HeaderLink to='news' />
            <HeaderLink to='help' />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Box component='ul' className={list} my={0}>
            <HeaderLink to='search'>
              <Search />
            </HeaderLink>
            <HeaderLink to='mypage'>
              <MyPage />
            </HeaderLink>
            <HeaderLink to='basket'>
              <Basket />
            </HeaderLink>
          </Box>
        </Box>
      </Box>
    </Header>
  );
}
