import { Box, Center, Text } from '@mantine/core';
import BarleyLeft from 'src/Icon/BarleyLeft';
import BarleyRight from 'src/Icon/BarleyRight';

export default function BarleyTitle({ children }: { children: string }) {
  return (
    <Box pt={48}>
      <Center>
        <BarleyLeft />
        <Text
          size={28}
          sx={{
            color: '#333333',
            fontFamily: 'Akshar',
            lineHeight: '30.8px',
          }}
          mx={30}
        >
          {children}
        </Text>
        <BarleyRight />
      </Center>
    </Box>
  );
}
