import type { Logo } from './LogoWhite';

export default function MyPage({
  variant = 'default',
  size = 28,
  ...others
}: Logo) {
  return (
    <svg
      {...others}
      width={size}
      height='31'
      viewBox='0 0 32 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.5707 7.63636C21.5707 10.7492 19.0766 13.2727 16 13.2727C12.9234 13.2727 10.4293 10.7492 10.4293 7.63636C10.4293 4.52349 12.9234 2 16 2C19.0766 2 21.5707 4.52349 21.5707 7.63636Z'
        stroke='white'
        strokeWidth='3'
      />
      <path
        d='M3.33448 24.7872C9.13043 15.2192 22.8696 15.2192 28.6655 24.7872L29.3084 25.8486C31.2043 28.9783 28.9784 33 25.3505 33H6.64954C3.02157 33 0.795723 28.9783 2.69156 25.8486L3.33448 24.7872Z'
        stroke='white'
        strokeWidth='3'
      />
    </svg>
  );
}
