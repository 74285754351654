import type { Logo } from './LogoWhite';

export default function Search({
  variant = 'default',
  size = 25,
  ...others
}: Logo) {
  return (
    <svg
      {...others}
      width={size}
      height='31'
      viewBox='0 0 29 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.6562 13.2375C22.6562 18.3241 18.4951 22.475 13.3281 22.475V26.475C20.6739 26.475 26.6562 20.5635 26.6562 13.2375H22.6562ZM13.3281 22.475C8.16117 22.475 4 18.3241 4 13.2375H0C0 20.5635 5.98238 26.475 13.3281 26.475V22.475ZM4 13.2375C4 8.15088 8.16117 4 13.3281 4V0C5.98238 0 0 5.91152 0 13.2375H4ZM13.3281 4C18.4951 4 22.6562 8.15088 22.6562 13.2375H26.6562C26.6562 5.91152 20.6739 0 13.3281 0V4ZM17.1815 22.9417L25.3846 34.1792L28.6154 31.8208L20.4123 20.5833L17.1815 22.9417Z'
        fill='white'
      />
    </svg>
  );
}
