import type { Logo } from './LogoWhite';

export default function Basket({
  variant = 'default',
  size = 38,
  ...others
}: Logo) {
  return (
    <svg
      {...others}
      width={size}
      height='31'
      viewBox='0 0 40 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 2H7.03507C8.22843 2 9.30831 2.7073 9.78516 3.80124L17.5 21.5H34L38.1251 12.2184C39.0069 10.2346 37.5547 8 35.3837 8H25.5M21.5 30C21.5 31.6569 20.1569 33 18.5 33C16.8431 33 15.5 31.6569 15.5 30C15.5 28.3431 16.8431 27 18.5 27C20.1569 27 21.5 28.3431 21.5 30ZM35.5 30C35.5 31.6569 34.1569 33 32.5 33C30.8431 33 29.5 31.6569 29.5 30C29.5 28.3431 30.8431 27 32.5 27C34.1569 27 35.5 28.3431 35.5 30Z'
        stroke='white'
        strokeWidth='3'
      />
    </svg>
  );
}
