import { ReactNode } from 'react';
import { Box, Text } from '@mantine/core';
import Spot from './Spot';

export default function Position({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: 'fit-content',
        alignItems: 'center',
        margin: '9px 0',
      }}
    >
      <Spot />
      <Text size={20} sx={{ fontWeight: 700, lineHeight: '25px' }}>
        {children}
      </Text>
      <Spot />
    </Box>
  );
}
