import { ReactNode } from 'react';
import { Box, Text } from '@mantine/core';
import Spot from './Spot';

export default function Description({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Spot size={12} margin='0 12px 0 0' color='#E1A003' />
      <Text size={18} weight={300}>
        {children}
      </Text>
    </Box>
  );
}
