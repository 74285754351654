import {
  ListItemProps,
  MantineThemeOverride,
  SkeletonProps,
} from '@mantine/core';

const ListDefaultProps: Partial<ListItemProps> = {
  styles: { itemWrapper: { width: '100%' } },
};

const SkeletonDefaultProps: Partial<SkeletonProps> = {
  sx: (theme) => ({
    backgroundColor: theme.colors.dark[7],
    '&:after': {
      backgroundColor: theme.colors.dark[7],
    },
    '&:before': {
      backgroundColor: theme.colors.dark[7],
    },
  }),
};

const ButtonDefaultProps = {
  styles: {
    root: {
      '&:focus': {
        outline: 'none',
      },
    },
  },
};

const ContainerDefaultProps = {
  size: 'lg',
};

export const theme: MantineThemeOverride = {
  fontFamily: 'Lexend Deca, Akshar, Alatsi, sans-serif',
  colors: {
    kakaoYellow: ['#fef11c', '#fde600', '#fcd900', '#fbcf00', '#fac500'],
    // yellow: ['#ffcb14'],

    yellow: [
      '#fff9db',
      '#fff3bf',
      '#ffec99',
      '#ffe066',
      '#ffd43b',
      '#fcc419',
      '#fab005',
      '#f59f00',
      '#f08c00',
      '#e67700',
    ],
  },
  components: {
    List: { defaultProps: ListDefaultProps },
    Skeleton: { defaultProps: SkeletonDefaultProps },
    Button: { defaultProps: ButtonDefaultProps },
    Container: { defaultProps: ContainerDefaultProps },
  },
};
