import { Text, Box } from '@mantine/core';
import { Link } from 'react-router-dom';

interface HeaderLink {
  to?: string;
  children?: React.ReactNode;
}

export default function HeaderLink({ to, children }: HeaderLink) {
  return (
    <li>
      <Link to={`/${to ?? ''}`} style={{ textDecoration: 'none' }}>
        {children ? (
          <Box sx={{ height: 31 }} mr={54} ml={to ? 0 : 54}>
            {children}
          </Box>
        ) : (
          <Text
            sx={{
              color: 'white',
              marginRight: '54px',
              marginLeft: `${to ? 0 : '54px'}`,
            }}
          >
            {typeof to === 'undefined' ? 'HOME' : to.toUpperCase()}
          </Text>
        )}
      </Link>
    </li>
  );
}
