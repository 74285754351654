import { Box, Center, Text } from '@mantine/core';
import BarleyTitle from 'src/components/common/BarleyTitle';
import useAboutUsPageStyles from './styles/useAboutUsPageStyles';
import Profile from './Profile/Profile';
import ProfileImage from 'src/asset/image/profile_image.jpg';

export default function AboutUs() {
  const { classes } = useAboutUsPageStyles();
  const { wrapper } = classes;
  return (
    <>
      <Box component='section' className={wrapper}>
        <Center mt={96} sx={{ flexDirection: 'column' }}>
          <Text align='center' color='white' size={96} sx={{ fontWeight: 500 }}>
            ABOUT US
          </Text>
        </Center>
      </Box>
      <section>
        <BarleyTitle>OUR TEAM</BarleyTitle>
        <Text
          align='center'
          color='#D3D3D3'
          size={15}
          mt={76}
          sx={{ lineHeight: '22.5px', zIndex: 2 }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          <br />
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Quis ipsum suspendisse ultrices gravida.
          <br />
          <br />
          Risus commodo viverra maecenas accumsan lacus vel facilisis.
        </Text>
      </section>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Profile
          img={ProfileImage}
          korName='김창수'
          engName='CHANG-SOO KIM'
          position='CEO'
        />
      </Box>
    </>
  );
}
