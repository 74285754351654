import { createStyles } from '@mantine/core';
import aboutUsHeaderBackground from 'src/asset/image/about_us.jpg';

const useAboutUsPageStyles = createStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: '70vh',
    backgroundImage: `url(${aboutUsHeaderBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

export default useAboutUsPageStyles;
