export default function Spot({
  size,
  color,
  margin,
}: {
  size?: number;
  color?: string;
  margin?: string;
}) {
  return (
    <div
      style={{
        borderRadius: '50%',
        backgroundColor: `${color ?? 'black'}`,
        width: `${size ?? 4}px`,
        height: `${size ?? 4}px`,
        margin: `${margin ?? '0 11px'}`,
      }}
    />
  );
}
