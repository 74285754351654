import { createStyles } from '@mantine/core';
import type { MantineTheme } from '@mantine/core';

const useHeaderStyles = createStyles(
  (_: MantineTheme, isAuth: boolean) => ({
    wrapper: {
      position: 'fixed',
      left: 0,
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderBottom: 'none',
      backgroundColor: 'rgba( 0, 0, 0, 0.0 )',
    },
    inner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    list: {
      display: isAuth ? 'flex' : 'none',
      listStyle: 'none',
    },
  }),
);

export default useHeaderStyles;
