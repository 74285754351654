import { MantineProvider } from '@mantine/core';
import ReactDOM from 'react-dom/client';
import App from './App';
import { theme } from './theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
    <App />
  </MantineProvider>,
);
